var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tw-flex tw-flex-col tw-gap-6"
  }, [_c('div', {
    staticClass: "tips-description tp-6",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('scenes.ekyc.destination-address.content'))
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "general-input tw-w-full tw-flex-1"
  }, [_c('div', {
    class: ['field field-text', {
      'field-invalid': _vm.errors.has('address'),
      'field-filled': _vm.address.length
    }]
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.address,
      expression: "address"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    class: ['input', {
      invalid: _vm.errors.has('address'),
      empty: _vm.address.length <= 0
    }],
    attrs: {
      "id": "address",
      "name": "address",
      "data-vv-validate-on": "blur",
      "type": "text"
    },
    domProps: {
      "value": _vm.address
    },
    on: {
      "focus": function ($event) {
        return _vm.$validator.errors.remove('address');
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.address = $event.target.value;
      }
    }
  }), _vm._v(" "), _c('label', {
    attrs: {
      "for": "destination-address"
    }
  }, [_vm._v(_vm._s(_vm.$t('placeholders.text.field.enter-address')))])]), _vm._v(" "), _vm.errors.has('address') ? _c('div', {
    staticClass: "field-error"
  }, [_c('p', [_vm._v(_vm._s(_vm.errors.first('address')))])]) : _vm._e()]), _vm._v(" "), _c('button', {
    staticClass: "kycVerifyModal-continueBtn call-to-action dark d-block btn-primary-hv",
    attrs: {
      "data-testid": "eKYC-continue-button"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v("\n    " + _vm._s(_vm.$t('global.continue')) + "\n  ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }