var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--freemium-country-selector freemium-country tw-w-full tw-rounded tw-border tw-border-solid tw-border-primary tw-pb-[16px] tw-px-[16px] tw-mb-6",
    attrs: {
      "data-testid": "freemium-destinations-btn"
    }
  }, [_c('div', {
    staticClass: "freemium-country-title tw-flex tw-items-center tw-text-3 tw-font-semibold tw-leading-3 tw-tracking-wide tw-uppercase tw-cursor-pointer tw-select-none tw-pt-[16px]",
    on: {
      "click": _vm.expandValidCountries
    }
  }, [_c('i', {
    class: ['airalo-icon-play3 ltr:tw-mr-1.25 rtl:tw-ml-1.25 rtl:tw-rotate-180', {
      'ltr:tw-rotate-90 rtl:tw-rotate-90': _vm.isExpanded
    }],
    attrs: {
      "data-testid": "freemium-country-icon"
    }
  }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.$t('components.freemium.view-valid-destinations')))])]), _vm._v(" "), _vm.isExpanded ? _c('div', {
    staticClass: "tw-mt-4 tw-pt-4 tw-text-14 tw-leading-[20px] tw-border-0 tw-border-t tw-border-solid tw-border-zinc-150 tw-relative"
  }, [_c('div', {
    staticClass: "freemium-country-list-search-container"
  }, [_vm._m(0), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchValue,
      expression: "searchValue"
    }],
    staticClass: "tw-w-full freemium-country-search",
    attrs: {
      "placeholder": _vm.$t('components.freemium.country-name'),
      "data-testid": "freemium-country-search-bar",
      "type": "text"
    },
    domProps: {
      "value": _vm.searchValue
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchValue = $event.target.value;
      }
    }
  }), _vm._v(" "), _vm.searchValue.length > 0 ? _c('span', {
    staticClass: "icon close-icon tw-cursor-pointer tw-absolute tw-top-[27px] tw-right-[12px]",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.searchValue = '';
      }
    }
  }, [_c('i', {
    staticClass: "airalo-icon-close-round-filled"
  })]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "freemium-country-list-countries tw-mt-2 tw-max-h-[200px] tw-overflow-y-auto",
    attrs: {
      "data-testid": "freemium-country-list"
    }
  }, _vm._l(_vm.searchedCountryList, function (country) {
    return _c('div', {
      staticClass: "tw-py-4 tw-border-0 tw-border-b tw-border-solid tw-border-zinc-150 tw-flex tw-items-center tw-justify-between tw-cursor-pointer",
      on: {
        "click": function ($event) {
          return _vm.redirectToFreemiumPackage(country);
        }
      }
    }, [_c('div', [_c('img', {
      staticClass: "ltr:tw-mr-2 rtl:tw-ml-2",
      attrs: {
        "src": country.image.url,
        "alt": country.title,
        "width": "37px",
        "height": "24px",
        "data-testid": `${country.title}-freemium-search-result`
      }
    }), _vm._v(" "), _c('span', {
      staticClass: "tw-text-15 tw-font-semibold"
    }, [_vm._v(_vm._s(country.title) + " ")])]), _vm._v(" "), _c('i', {
      staticClass: "airalo-icon-arrow-right tw-text-primary tw-text-3 rtl:tw-rotate-180"
    })]);
  }), 0)]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "icon search tw-absolute tw-top-[29px] tw-left-[12px]"
  }, [_c('i', {
    staticClass: "airalo-icon-search"
  })]);

}]

export { render, staticRenderFns }