var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--sim_item",
    class: [`sim-item theme-${_vm.simTheme}`, {
      'my-esim': _vm.type === 'my-esims'
    }],
    style: _vm.simGradient
  }, [_vm.item.freemium ? [_c('div', {
    staticClass: "tw-flex tw-bg-white tw-py-8 tw-px-4 tw-rounded-t tw-justify-between tw-items-center",
    attrs: {
      "data-testid": "freemium-header"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        _vm.freemiumModalOpen = true;
      }
    }
  }, [_c('div', {
    staticClass: "tw-flex"
  }, [_c('i', {
    staticClass: "airalo-icon-star tw-text-primary tw-text-4 tw-mr-2",
    style: {
      color: _vm.item.operator.gradient_start
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "tw-text-4 tw-text-primary tw-font-semibold"
  }, [_vm._v("\n          " + _vm._s(_vm.$t('components.freemium.free-welcome-esim')) + "\n        ")])]), _vm._v(" "), _c('i', {
    staticClass: "airalo-icon-arrow-right rtl:tw-rotate-180 tw-text-primary tw-text-3"
  })])] : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "sim-item-header tw-relative"
  }, [_vm.packageHasPromotionAndStock ? [_c('div', {
    staticClass: "tw-absolute -tw-top-2.5 ltr:tw-left-5 rtl:tw-right-5"
  }, [_c('PromotionBadge', {
    attrs: {
      "percentage": _vm.pkgMxn_packagePromotionDiscount
    }
  })], 1)] : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "sim-item-header-info"
  }, [_c('p', {
    staticClass: "operator-title",
    attrs: {
      "data-testid": "operator-title"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.item.label || _vm.item.operator.title) + "\n      ")])]), _vm._v(" "), _c('div', {
    staticClass: "sim-item-image",
    class: {
      outOfStockGrayScale: _vm.isOutOfStock
    }
  }, [_vm.item.operator.image && _vm.item.operator.image.url ? [_c('img', {
    directives: [{
      name: "lazy-load",
      rawName: "v-lazy-load"
    }],
    attrs: {
      "src": `/assets/images/placeholders/sim-operator-small-${_vm.simTheme === 'dark' ? 'dark' : 'light'}.svg`,
      "data-src": _vm.item.operator.image.url,
      "width": "140",
      "alt": _vm.item.operator.title || ''
    }
  })] : [_c('img', {
    attrs: {
      "src": `/assets/images/placeholders/sim-operator-small-${_vm.simTheme === 'dark' ? 'dark' : 'light'}.svg`,
      "width": "140",
      "alt": "airalo",
      "data-not-lazy": ""
    }
  })]], 2)], 2), _vm._v(" "), _vm.type === 'store' ? _c('div', {
    staticClass: "sim-item-info"
  }, [_c('ul', {
    staticClass: "sim-item-list"
  }, [_vm.listType === 'local-packages' ? _c('li', [_c('sim-item-row', {
    staticClass: "coverage",
    attrs: {
      "theme": _vm.simTheme,
      "icon": "airalo-icon-globe-2",
      "key-text": _vm.$t('global.coverage'),
      "value": `${_vm.country ? _vm.country.title : ''}`
    }
  })], 1) : _c('li', {
    staticClass: "pointered",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clickCallToAction('open-supported-countries');
      }
    }
  }, [_c('sim-item-row', {
    staticClass: "coverage button tw-uppercase",
    attrs: {
      "theme": _vm.simTheme,
      "icon": "airalo-icon-globe-2",
      "key-text": _vm.$t('global.coverage'),
      "value": `${_vm.item.operator.countries.length} ${_vm.$t('global.countries')}`
    }
  })], 1), _vm._v(" "), _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.simTheme,
      "icon": "airalo-icon-arrows-up-and-down",
      "key-text": _vm.$t('global.data'),
      "value": _vm.pkgMxn_packageHasUnlimitedData ? _vm.$t('global.data-unlimited') : _vm.item.data
    }
  })], 1), _vm._v(" "), _vm.pkgMxn_packageHasDVTVoice ? _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.simTheme,
      "icon-is-svg": true,
      "icon": "ic_calls",
      "key-text": _vm.$t('global.calls'),
      "value": _vm.pkgMxn_packageHasUnlimitedVoice ? _vm.$t('global.calls-unlimited') : _vm.$t('global.minutes', {
        amount: _vm.item.voice
      })
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.pkgMxn_packageHasDVTText ? _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.simTheme,
      "icon-is-svg": true,
      "icon": "ic_sms",
      "key-text": _vm.$t('global.texts'),
      "value": _vm.pkgMxn_packageHasUnlimitedText ? _vm.$t('global.texts-unlimited') : _vm.$t('global.sms', {
        amount: _vm.item.text
      })
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.simTheme,
      "icon": "airalo-icon-calendar",
      "key-text": _vm.$t('global.validity'),
      "value": `${_vm.item.day} ${_vm.dayText(_vm.item.day)}`
    }
  })], 1), _vm._v(" "), _vm.item.is_stock ? _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.simTheme,
      "icon-is-svg": true,
      "icon": "ic_price_v2",
      "key-text": _vm.$t('global.price'),
      "value": _vm.pkgMxn_packageNonPromotedPrice.formatted,
      "is-last": true,
      "is-price": true,
      "price-has-promotion": _vm.packageHasPromotionAndStock,
      "is-promoted-price": false
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.packageHasPromotionAndStock ? _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.simTheme,
      "value": _vm.pkgMxn_packagePromotedPrice.formatted,
      "is-last": true,
      "is-price": true,
      "is-promoted-price": true,
      "discount-percentage": _vm.pkgMxn_packagePromotionDiscount,
      "sale-price-end-date-iso": _vm.pkgMxn_packagePromotionEndDateIso,
      "tooltip-target-id": _vm.tooltipTargetId
    }
  })], 1) : _vm._e()])]) : _vm.type === 'my-esims' ? _c('div', {
    staticClass: "sim-item-info"
  }, [_c('ul', {
    staticClass: "sim-item-list"
  }, [_vm.item.freemium ? _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.simTheme,
      "icon": "airalo-icon-star",
      "key-text": _vm.$t('components.freemium.welcome-esim'),
      "value": _vm.$t('components.freemium.free')
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('li', [_c('sim-item-row', {
    staticClass: "iccid",
    attrs: {
      "theme": _vm.simTheme,
      "icon": "airalo-icon-myesim",
      "key-text": "ICCID",
      "value": `${_vm.item.iccid}`
    }
  })], 1), _vm._v(" "), _vm.item.operator.country ? _c('li', [_c('sim-item-row', {
    staticClass: "coverage",
    attrs: {
      "theme": _vm.simTheme,
      "icon": "airalo-icon-globe-2",
      "key-text": _vm.$t('global.coverage'),
      "value": `${_vm.item.operator.country.title}`
    }
  })], 1) : !_vm.item.operator.country && _vm.item.operator.countries && _vm.item.operator.countries.length > 0 ? _c('li', {
    staticClass: "pointered",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clickCallToAction('open-supported-countries');
      }
    }
  }, [_c('sim-item-row', {
    staticClass: "coverage button tw-uppercase",
    attrs: {
      "theme": _vm.simTheme,
      "icon": "airalo-icon-globe-2",
      "key-text": _vm.$t('global.coverage'),
      "value": `${_vm.item.operator.countries.length} ${_vm.$t('global.countries')}`
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('li', {
    staticClass: "sim-item-usage-data"
  }, [_c('sim-item-row', {
    staticClass: "sim-item-row-key-text",
    attrs: {
      "theme": _vm.simTheme,
      "icon": "airalo-icon-arrows-up-and-down",
      "key-text": _vm.dataUsageLabel,
      "value": _vm.usageData && _vm.usageData.data ? _vm.usageData.data : '...',
      "loader": (!_vm.usageData || !_vm.usageData.data) && _vm.isLoading,
      "is-last": !_vm.showRenewals,
      "is-remaining-usage": true,
      "remaining-usage-type": "data"
    }
  })], 1), _vm._v(" "), _vm.checkRemainingVoiceText ? _c('li', {
    staticClass: "sim-item-usage-voice"
  }, [_c('sim-item-row', {
    staticClass: "sim-item-row-key-text",
    attrs: {
      "theme": _vm.simTheme,
      "icon-is-svg": true,
      "icon": "ic_calls",
      "key-text": _vm.voiceUsageLabel,
      "value": _vm.usageData && _vm.usageData.data ? _vm.usageData.data : '...',
      "loader": (!_vm.usageData || !_vm.usageData.data) && _vm.isLoading,
      "is-last": false,
      "is-remaining-usage": true,
      "remaining-usage-type": "voice"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.checkRemainingVoiceText ? _c('li', {
    staticClass: "sim-item-usage-text"
  }, [_c('sim-item-row', {
    staticClass: "sim-item-row-key-text",
    attrs: {
      "theme": _vm.simTheme,
      "icon-is-svg": true,
      "icon": "ic_sms",
      "key-text": _vm.textUsageLabel,
      "value": _vm.usageData && _vm.usageData.data ? _vm.usageData.data : '...',
      "loader": (!_vm.usageData || !_vm.usageData.data) && _vm.isLoading,
      "is-last": false,
      "is-remaining-usage": true,
      "remaining-usage-type": "text"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.showRenewals && _vm.item.renewal && _vm.item.renewal.status === 'enabled' ? _c('li', {
    staticClass: "sim-item-usage-renewals"
  }, [_c('sim-item-row', {
    staticClass: "sim-item-row-key-text",
    attrs: {
      "theme": _vm.simTheme,
      "icon-is-svg": true,
      "icon": "ic_subscription",
      "key-text": _vm.$t('global.renewal').toUpperCase(),
      "value": _vm.item.renewal.data,
      "is-last": true,
      "has-colors-inverted": true,
      "inverted-text-color": _vm.item.operator.gradient_end
    }
  })], 1) : _vm._e()])]) : _vm._e(), _vm._v(" "), _vm.type === 'store' ? _c('div', {
    staticClass: "sim-item-bottom",
    class: {
      'sim-item-has-promotion tw-promo-prices-bg': _vm.packageHasPromotionAndStock
    }
  }, [_c('div', {
    staticClass: "sim-item-bottom-button d-flex",
    attrs: {
      "data-testid": "esim-button"
    }
  }, [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "sim-item-btn"
    }
  }, [_vm._v(_vm._s(_vm.bottomButtonText))])], 1)]) : _vm.type === 'my-esims' ? _c('div', {
    staticClass: "sim-item-bottom"
  }, [_c('div', {
    staticClass: "sim-item-bottom-button d-grid d-grid-fit gap-20"
  }, [_vm.showRenewals && _vm.item.renewal && _vm.item.renewal.status === 'enabled' ? [_c('nuxt-link', {
    staticClass: "button",
    class: {
      'button-secondary-outline': _vm.simTheme === 'dark',
      'button-primary-outline': _vm.simTheme !== 'dark'
    },
    attrs: {
      "to": _vm.localePath({
        name: 'my-esims-detail-id',
        params: {
          id: _vm.item.id
        }
      }),
      "data-testid": "details-button"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('global.package-details').toUpperCase()) + "\n        ")])] : _vm.isKycDestinationAddressMissing ? [_c('div', {
    staticClass: "button tw-cursor-pointer",
    class: [_vm.simTheme === 'dark' ? 'button-secondary btn-primary-hv' : 'button-primary'],
    on: {
      "click": _vm.handleDestinationAddressModal
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('global.ekyc.destination-address-missing')) + "\n        ")])] : _vm.eKycOptions.required && _vm.eKycOptions.isPending ? [_c('div', {
    staticClass: "button tw-cursor-pointer",
    class: [_vm.simTheme === 'dark' ? 'button-secondary btn-primary-hv' : 'button-primary'],
    on: {
      "click": _vm.openKycDetailsModal
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('component.esim.item.button.verify-pending')) + "\n        ")])] : _vm.eKycOptions.required && _vm.eKycOptions.isRejectedOrExpired ? [_c('div', {
    staticClass: "button tw-cursor-pointer",
    class: [_vm.simTheme === 'dark' ? 'button-secondary btn-primary-hv' : 'button-primary'],
    on: {
      "click": _vm.eKycSubmit
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('global.ekyc-procced-resubmit')) + "\n        ")])] : [_vm.hasTopups ? _c('nuxt-link', {
    staticClass: "button",
    class: {
      'button-secondary-outline': _vm.simTheme === 'dark',
      'button-primary-outline': _vm.simTheme !== 'dark'
    },
    attrs: {
      "to": _vm.localePath({
        name: 'my-esims-detail-id',
        params: {
          id: _vm.item.id
        },
        hash: '#topup'
      }),
      "data-testid": "top-up-button"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('global.top-up')) + "\n        ")]) : _vm._e(), _vm._v(" "), _c('nuxt-link', {
    staticClass: "button",
    class: {
      'button-secondary btn-primary-hv': _vm.simTheme === 'dark',
      'button-primary': _vm.simTheme !== 'dark'
    },
    attrs: {
      "to": _vm.localePath({
        name: 'my-esims-detail-id',
        params: {
          id: _vm.item.id
        }
      }),
      "data-testid": "details-button"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('global.details')) + "\n        ")])]], 2)]) : _vm._e(), _vm._v(" "), _vm.showCoBrandedLoyaltyRewards ? _c('div', {
    staticClass: "sim-item-bottom tw-bg-white tw-rounded-b-[7px]"
  }, [_c('sim-promotion-loyalty-reward', {
    attrs: {
      "item": _vm.item,
      "text-size": "sm",
      "heading-text": _vm.$t('components.branding.loyalty.estimated-rewards'),
      "info-tooltip": "",
      "info-tooltip-text": _vm.$t('components.branding.loyalty.estimated-rewards-disclaimer')
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('HowFreemiumWorks', {
    attrs: {
      "view-modal": _vm.freemiumModalOpen
    },
    on: {
      "onClose": function ($event) {
        _vm.freemiumModalOpen = false;
      }
    }
  }), _vm._v(" "), _vm.destinationAddressIdentity ? [_c('DestinationAddressModal', {
    attrs: {
      "show": _vm.showDestinationAddressModal,
      "identity": _vm.destinationAddressIdentity
    },
    on: {
      "close": _vm.closeDestinationAddressModal
    }
  })] : _vm._e(), _vm._v(" "), _vm.loading ? [_c('client-only', [_c('v-lottie', {
    attrs: {
      "position": "fixed"
    }
  })], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }