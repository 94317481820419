var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.$vnode.tag,
      "centered": "",
      "hide-footer": "",
      "dialog-class": "kycVerifyModal-dialog",
      "header-class": "kycVerifyModal-header",
      "body-class": "kycVerifyModal-body"
    },
    on: {
      "hide": _vm.kycVerifyModalHidden
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function () {
        return [_c('h2', {
          staticClass: "package-kycVerifyModal-title"
        }, [_vm._v("\n      " + _vm._s(_vm.$t('scenes.ekyc.destination-address.title')) + "\n    ")])];
      },
      proxy: true
    }, {
      key: "modal-header-close",
      fn: function () {
        return [_c('i', {
          staticClass: "airalo-icon-close-round-filled tw-block tw-text-5.5 tw-mt-2.5 tw-mr-2.5"
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm._v(" "), _vm._v(" "), _c('DestinationAddress', {
    on: {
      "click": _vm.submitDestinationAddress
    }
  }), _vm._v(" "), _c('client-only', [_vm.loading ? [_c('v-lottie', {
    attrs: {
      "position": "fixed"
    }
  })] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }