<template>
  <div class="tw-flex tw-flex-col tw-gap-6">
    <div v-html="$t('scenes.ekyc.destination-address.content')" class="tips-description tp-6" />

    <div class="general-input tw-w-full tw-flex-1">
      <div :class="['field field-text', { 'field-invalid': errors.has('address'), 'field-filled': address.length }]">
        <input
          id="address"
          name="address"
          v-model="address"
          v-validate="'required'"
          data-vv-validate-on="blur"
          @focus="$validator.errors.remove('address')"
          :class="['input', { invalid: errors.has('address'), empty: address.length <= 0 }]"
          type="text"
        />
        <label for="destination-address">{{ $t('placeholders.text.field.enter-address') }}</label>
      </div>

      <div class="field-error" v-if="errors.has('address')">
        <p>{{ errors.first('address') }}</p>
      </div>
    </div>

    <button
      class="kycVerifyModal-continueBtn call-to-action dark d-block btn-primary-hv"
      data-testid="eKYC-continue-button"
      @click="submitForm"
    >
      {{ $t('global.continue') }}
    </button>
  </div>
</template>

<script>
  export default {
    name: 'destination-address',
    data() {
      return {
        address: '',
      }
    },
    methods: {
      submitForm() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$emit('click', this.address)
          }
        })
      },
    },
  }
</script>
