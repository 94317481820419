var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--cobranded-sim-loyalty-reward tw-bg-transparent tw-flex tw-justify-start tw-items-center tw-w-full md:tw-w-auto",
    class: {
      'tw-justify-between': _vm.textColumnOrientation
    }
  }, [_vm.showIcon ? _c('img', {
    staticClass: "tw-w-8 tw-h-8 tw-rounded tw-object-contain",
    attrs: {
      "src": _vm.logoUrl,
      "alt": "Loyalty Logo"
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "loyalty-reward-text tw-flex tw-justify-between tw-items-center tw-w-full tw-flex-nowrap tw-flex-grow",
    class: {
      'tw-flex-col !tw-items-start !tw-justify-start tw-gap-y-1': _vm.textColumnOrientation
    }
  }, [_c('span', {
    class: [_vm.fontColourClass, {
      'tw-font-semibold': _vm.headingBold,
      'tw-font-normal': !_vm.headingBold,
      'tw-text-[.65rem] tw-leading-[1.125rem]': _vm.isTextSizeSm,
      'tw-text-[.75rem] tw-tracking-[.0625rem]': _vm.isTextSizeMd,
      'tw-text-[.8125rem] tw-leading-[1.125rem]': _vm.isTextSizeLg
    }]
  }, [_vm._v("\n      " + _vm._s(_vm.headingText) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "tw-font-semibold tw-flex tw-gap-x-1 tw-justify-end tw-items-center tw-flex-nowrap tw-flex-grow",
    class: [_vm.fontColourClass, {
      'tw-font-semibold': _vm.rewardTextBold,
      'tw-font-normal': !_vm.rewardTextBold,
      'tw-text-[.875rem] tw-leading-[1.375rem]': _vm.isTextSizeSm,
      'tw-text-[1rem] tw-leading-[.875rem]': _vm.isTextSizeMd,
      'tw-text-[1.125rem] tw-leading-[1.375rem] -tw-tracking-[.0125rem]': _vm.isTextSizeLg
    }]
  }, [_vm._v("\n      " + _vm._s(_vm.rewardText) + "\n      "), _vm.infoTooltip && _vm.infoTooltipPosition === 'reward' && !!_vm.uniqueComponentId ? _c('div', {
    staticClass: "tw-z-50"
  }, [_c('span', {
    staticClass: "tw-flex",
    attrs: {
      "id": `reward-info-tooltip-${_vm.uniqueComponentId}`
    }
  }, [_c('i', {
    staticClass: "airalo-icon-exclamation-mark-round pointered",
    class: _vm.fontColourClass
  })]), _vm._v(" "), _c('Tooltip', {
    attrs: {
      "target": `reward-info-tooltip-${_vm.uniqueComponentId}`,
      "triggers": "hover",
      "text": _vm.infoTooltipText,
      "button-text": _vm.$t('components.tooltip.got-it-button'),
      "is-clone-target": true
    }
  })], 1) : _vm._e()])]), _vm._v(" "), _vm.infoTooltip && _vm.infoTooltipPosition !== 'reward' && !!_vm.uniqueComponentId ? _c('div', {
    staticClass: "tw-z-50"
  }, [_c('span', {
    staticClass: "tw-flex",
    attrs: {
      "id": `reward-info-tooltip-${_vm.uniqueComponentId}`
    }
  }, [_c('i', {
    staticClass: "airalo-icon-exclamation-mark-round pointered",
    class: _vm.fontColourClass
  })]), _vm._v(" "), _c('Tooltip', {
    attrs: {
      "target": `reward-info-tooltip-${_vm.uniqueComponentId}`,
      "triggers": "hover",
      "text": _vm.infoTooltipText,
      "button-text": _vm.$t('components.tooltip.got-it-button'),
      "is-clone-target": true
    }
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }