<template>
  <b-modal
    :id="$vnode.tag"
    v-model="show"
    centered
    hide-footer
    @hide="kycVerifyModalHidden"
    dialog-class="kycVerifyModal-dialog"
    header-class="kycVerifyModal-header"
    body-class="kycVerifyModal-body"
  >
    <template #modal-title>
      <h2 class="package-kycVerifyModal-title">
        {{ $t('scenes.ekyc.destination-address.title') }}
      </h2>
    </template>

    <template #modal-header-close>
      <i class="airalo-icon-close-round-filled tw-block tw-text-5.5 tw-mt-2.5 tw-mr-2.5" />
    </template>

    <DestinationAddress @click="submitDestinationAddress" />

    <client-only>
      <template v-if="loading">
        <v-lottie position="fixed" />
      </template>
    </client-only>
  </b-modal>
</template>

<script>
  import DestinationAddress from '@/components/shared/kyc-verify-modal/destination-address.vue'
  import { mapActions } from 'vuex'

  export default {
    name: 'modals_destination-address',
    components: {
      DestinationAddress,
    },
    props: {
      show: {
        type: Boolean,
        required: true,
      },
      identity: {
        type: Object,
        required: true,
      },
      id: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        loading: false,
      }
    },
    methods: {
      ...mapActions({
        saveDestinationAddress: 'eKyc/save_destination_address',
      }),
      async submitDestinationAddress(destinationAddress) {
        try {
          this.loading = true

          await this.saveDestinationAddress({
            destination: destinationAddress,
            identityID: this.identity.id,
            packageID: this.id,
          })

          this.$emit('close', 'success')
          this.$nextTick(() => {
            this.loading = false
          })
        } catch (error) {
          this.loading = false

          this.$bvToast.toast(error.response.data.message, {
            title: error.response.data.title,
            variant: 'danger',
            solid: true,
          })
        }
      },
      kycVerifyModalHidden(evt) {
        if (['backdrop', 'esc', 'event', 'headerclose'].includes(evt.trigger)) {
          evt.preventDefault()
          this.$emit('close')
        }
      },
    },
  }
</script>

<style lang="scss">
  .kycVerifyModal-dialog .modal-header .close {
    [dir='rtl'] & {
      margin: -1rem auto -1rem -1rem;
    }
  }

  .kycVerifyModal-dialog {
    &.modal-md {
      max-width: 540px;
    }

    .modal-content {
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    }
  }

  .kycVerifyModal-header {
    .package-kycVerifyModal-title {
      color: #4a4a4a;
      font-size: 21px;
      font-weight: 600;
      letter-spacing: -0.3px;
      line-height: 25px;
      margin: 24px 0 2px 4px;
    }

    button.close {
      padding: 0;
      opacity: 1;

      img {
        margin-top: 9px;
        margin-right: 9px;

        [dir='rtl'] & {
          margin-right: unset;
          margin-left: 9px;
        }
      }
    }
  }

  .kycVerifyModal-body {
    padding: 20px 20px 40px 20px;

    .kycVerifyModal-content {
      .tips-description {
        color: #8a8a8a;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 21px;

        ul > li:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      .kycVerifyModal-continueBtn {
        color: #ffffff;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 11px;
        text-align: center;
        padding: 16px 20px 15px;
      }
    }

    .embed-responsive {
      min-height: 70vh;
    }
  }
</style>
